<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">属性名称:</span>
              <el-input placeholder="属性名称" size="small" style="width:265px" v-model="name" clearable
                        class="handle-input mr10"></el-input>
              <el-row type="flex" justify="end" style="margin-left: 30px">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="80" class="th-l">属性名称</th>
                <th width="130" class="th-l">属性列表</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.attributeName}}</span></td>
                <td class="td-l"><span>{{row.specs.replace(/\[|]|"/g,'')}}</span></td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>


    <el-dialog title="新增" width="600px" v-if="addVisible" :visible.sync="addVisible" :before-close="closeAddEvent">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="名称:" required>
          <el-input v-model="attributeName" style="width: 420px"></el-input>
        </el-form-item>

        <el-form-item label="属性:" required style="width: 600px">
          <ul>
            <li v-for="(types,index) in specs" style="margin-bottom:10px;">
              <el-input size="small" v-model="types.name"
                        style="width:320px;margin:0 2px;margin-top: -10px">
              </el-input>
              <el-button type="text" style="margin: 0 8px" @click="addAttributes">新增
              </el-button>
              <el-button type="text" v-if="specs.length>1"
                         @click="byAttributeRemove(index)">删除
              </el-button>
            </li>
          </ul>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeAddEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveMspAttribute">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEditEvent">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="属性名称:" required>
          <el-input v-model="attributeName" style="width: 420px"></el-input>
        </el-form-item>
        <el-form-item label="属性:" required style="width: 600px">
          <ul>
            <li v-for="(types,index) in specs" style="margin-bottom:10px;">
              <el-input size="small" v-model="types.name"
                        style="width:320px;margin:0 2px;margin-top: -10px">
              </el-input>
              <el-button type="text" style="margin: 0 8px" @click="addAttributes">新增
              </el-button>
              <el-button type="text" v-if="specs.length>1"
                         @click="byAttributeRemove(index)">删除
              </el-button>
            </li>
          </ul>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeEditEvent">取 消</el-button>
                <el-button size="small" type="primary" @click="updateMspAttribute">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        name: '',
        id: '',
        attributeName: '',
        specs: [{ name: ''}],

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        addVisible: false,
        editVisible: false,
        delVisible: false,
        item: {},
        editItem: {},

        cardTypeMap: {},
        cardTypeList: [],

      }
    },
    async created() {
      this.searchEvent();
    },

    methods: {
      addAttributes() {
        this.specs.push({name: ''})
      },
      byAttributeRemove(index) {
        this.specs.splice(index, 1)
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          attributeName: this.name,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getMspAttributeForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      addEvent() {
          this.id = '',
          this.attributeName = '',
          this.specs = [{name: '',}],
          this.addVisible = true
      },
      closeAddEvent() {
        this.addVisible = false
      },

      editEvent(index) {
        this.specs = []
        this.id = this.list[index].id
        this.attributeName = this.list[index].attributeName
        let arr = JSON.parse(this.list[index].specs)
        arr.forEach(item => {
          let data = {
            name: item
          }
          this.specs.push(data)
        })
        this.editVisible = true
      },

      closeEditEvent() {
        this.editVisible = false
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },


      async saveMspAttribute() {
        if (!this.attributeName) {
          this.$message.error("请输入属性名称");
          return
        }
        if (!this.specs) {
          this.$message.error("请输入规格");
          return
        }

        let data = {
          attributeName: this.attributeName,
          specsStr: JSON.stringify(this.specs),
        };
        let res = await this.$post("/admin/save_mspAttribute", data)
        if (res.code == 200) {
          this.addVisible = false
          this.searchEvent()
          this.$message.success(res.message);
        } else {
          this.$message.success(res.message);
        }
      },

      async updateMspAttribute() {
        if (!this.attributeName) {
          this.$message.error("请输入属性名称");
          return
        }
        if (!this.specs) {
          this.$message.error("请输入规格");
          return
        }

        let data = {
          id: this.id,
          attributeName: this.attributeName,
          specsStr: JSON.stringify(this.specs),
        };
        let res = await this.$post("/admin/save_mspAttribute", data)
        if (res.code == 200) {
          this.editVisible = false
          this.searchEvent()
          this.$message.success(res.message);
        } else {
          this.$message.success(res.message);
        }
      },

      //删除
      async delMethod() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/admin/delete_mspAttribute", data)
        if (res.code == 200) {
          this.searchEvent();
          this.delVisible = false;
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
  .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
